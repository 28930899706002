
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import SurveyEmailQueWidget from "@/components/widgets/dashboard/SurveyEmailQue-Widget.vue";
import ClientSurveyFoldersWidget from "@/components/widgets/dashboard/Client-Survey-Folders-Widget.vue";
import ContentExpertWidget from "@/components/widgets/dashboard/Content-Expert-Widget.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    SurveyEmailQueWidget,
    ClientSurveyFoldersWidget,
    ContentExpertWidget,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const route = useRoute();
    const routeName = computed(() => route.name);
    onMounted(() => {
      setCurrentPageTitle("Dashboard");

      if (Object.keys(currentClient.value).length > 0) {
        isContentExpertReady.value = true;
      }
    });

    const isContentExpertReady = ref(false);

    watch(currentClient, () => {
      isContentExpertReady.value = true;
    });

    return {
      currentUser,
      currentClient,
      routeName,
      isContentExpertReady,
    };
  },
});
