
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "client-survey-folder-widget",
  components: {},
  props: ["surveytype"],
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    //const currentClientUser = store.getters.getCurrentClientInfo;
    const currentClientUser = computed(() => {
      return store.getters.getCurrentClientInfo;
    });

    watch(currentClientUser, () => {
      isDataReady.value = true;
    });

    return {
      currentClientUser,
      isDataReady,
    };
  },
});
