
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "content-expert",
  components: { TableSkeleton },
  props: ["clientid"],
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    const contentExpert = computed(() => {
      return store.getters.getClientContentExpert;
    });

    onMounted(() => {
      store
        .dispatch(Actions.GET_CONTENT_EXPERT, props.clientid)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving your content expert.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    watch(contentExpert, () => {
      isDataReady.value = true;
    });

    return {
      isDataReady,
      contentExpert,
    };
  },
});
