
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { EmailQueListRequestDto } from "../../../core/types/EmailTypes";
import { DeleteEmailQueRequestDto } from "../../../core/types/EmailTypes";

export default defineComponent({
  name: "survey-email-que-widget",
  components: { TableSkeleton },
  props: ["routeName", "surveytype"],
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const isDataReady = ref(false);
    const { d } = useI18n();
    const emailQueListRequestDto = ref<EmailQueListRequestDto>({
      userRole: "",
      userAspNetUserId: "",
      typeOfSurvey: props.surveytype,
      fromRoute: props.routeName,
    });

    const deleteEmailQueRequestDto = ref<DeleteEmailQueRequestDto>({
      id: 0,
      typeOfSurveyEmail: props.surveytype,
    });

    const ques = computed(() => {
      if (props.surveytype === "behavioral") {
        return store.getters.getAllQuedEmails;
      } else {
        return store.getters.getAllJobQuedEmails;
      }
    });

    const sendSurveyRouteName = computed(() => {
      let surveyRouteName = "";
      if (
        currentUser.role === "SuperAdmin" ||
        currentUser.role === "LicensedDistributor" ||
        currentUser.role === "LDAssociate"
      ) {
        surveyRouteName = "incline-email-survey";
      } else if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        surveyRouteName = "client-email-survey";
      }
      return surveyRouteName;
    });

    onMounted(() => {
      emailQueListRequestDto.value.userRole = currentUser.role;
      emailQueListRequestDto.value.userAspNetUserId = currentUser.id;
      store
        .dispatch(Actions.GET_ALL_EMAIL_QUES, emailQueListRequestDto.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving your sent emails.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    const deleteEmail = (id) => {
      Swal.fire({
        title: "Are you sure you want to remove this email?",
        showCancelButton: true,
        confirmButtonText: "Yes. Remove",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          deleteEmailQueRequestDto.value.id = id;

          store
            .dispatch(Actions.DELETE_EMAIL_QUE, deleteEmailQueRequestDto.value)
            .then(() => {
              Swal.fire("The email has been removed!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem removing the email.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    watch(ques, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      sendSurveyRouteName,
      isDataReady,
      emailQueListRequestDto,
      deleteEmailQueRequestDto,
      ques,
      d,
      deleteEmail,
    };
  },
});
