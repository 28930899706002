<template>
  <!--begin::Survey Email Que Widget -->
  <div class="card card-xxl-stretch mb-5 mb-xl-8">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span
          v-if="surveytype === 'behavioral'"
          class="card-label fw-bolder fs-3 mb-1"
          >Behavioral Survey Folders</span
        >
        <span v-if="surveytype === 'job'" class="card-label fw-bolder fs-3 mb-1"
          >Job Survey Folders</span
        >
        <span class="text-muted mt-1 fw-bold fs-7"
          >Click on folder to view surveys</span
        >
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3" style="padding-bottom: 0px !important">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-2
          "
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th>Location</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template
              v-for="(folder, index) in currentClientUser.folderListLineItems"
              :key="index"
            >
              <tr
                v-if="
                  surveytype === 'behavioral' &&
                  folder.folderName === 'Behavioral-Surveys'
                "
              >
                <td>
                  <p class="text-dark fw-bolder d-block fs-6">
                    <span class="svg-icon svg-icon-3 svg-icon-primary">
                      <inline-svg
                        src="../media/icons/duotune/files/fil012.svg"
                      />
                    </span>
                    <router-link
                      :to="{
                        name: 'client-survey-folder',
                        params: {
                          folderid: String(folder.folderId),
                          foldername: String(folder.folderName),
                          clientname: String(currentClientUser.client.name),
                          clientlocationname: String(folder.locationName),
                        },
                      }"
                    >
                      {{ folder.locationName }}</router-link
                    >
                  </p>
                </td>
              </tr>
              <tr
                v-if="
                  surveytype === 'job' && folder.folderName === 'JOB-Surveys'
                "
              >
                <td>
                  <p class="text-dark fw-bolder d-block fs-6">
                    <span class="svg-icon svg-icon-3 svg-icon-primary">
                      <inline-svg
                        src="../media/icons/duotune/files/fil012.svg"
                      />
                    </span>
                    <router-link
                      :to="{
                        name: 'client-job-survey-folder',
                        params: {
                          folderid: String(folder.folderId),
                          foldername: String(folder.folderName),
                          clientname: String(currentClientUser.client.name),
                          clientlocationname: String(folder.locationName),
                        },
                      }"
                    >
                      {{ folder.locationName }}</router-link
                    >
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!--end::Survey Email Que Widget-->
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "client-survey-folder-widget",
  components: {},
  props: ["surveytype"],
  setup() {
    const store = useStore();
    const isDataReady = ref(false);
    //const currentClientUser = store.getters.getCurrentClientInfo;
    const currentClientUser = computed(() => {
      return store.getters.getCurrentClientInfo;
    });

    watch(currentClientUser, () => {
      isDataReady.value = true;
    });

    return {
      currentClientUser,
      isDataReady,
    };
  },
});
</script>
