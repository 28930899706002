<template>
  <!--begin::Survey Email Que Widget -->
  <div class="card card-xxl-stretch mb-5 mb-xl-8" v-if="isDataReady">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span
          v-if="surveytype === 'behavioral'"
          class="card-label fw-bolder fs-3 mb-1"
          >Recent Emailed Surveys</span
        >
        <span v-if="surveytype === 'job'" class="card-label fw-bolder fs-3 mb-1"
          >Recent Emailed Job Surveys</span
        >
        <span
          v-if="surveytype === 'behavioral'"
          class="text-muted mt-1 fw-bold fs-7"
          >Incline Behavioral Surveys</span
        >
        <span v-if="surveytype === 'job'" class="text-muted mt-1 fw-bold fs-7"
          >Incline Job Surveys</span
        >
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to Send A Survey"
      >
        <router-link
          :to="{
            name: sendSurveyRouteName,
            params: {
              surveytype: surveytype,
            },
          }"
          class="btn btn-sm btn-light-primary"
        >
          Send A Survey<span class="svg-icon svg-icon-3">
            <inline-svg src="../media/icons/duotune/arrows/arr001.svg" /> </span
        ></router-link>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3" style="padding-bottom: 0px !important">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-2
          "
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th v-if="surveytype === 'behavioral'" class="min-w-150px">
                Recipient
              </th>
              <th v-if="surveytype === 'job'" class="min-w-150px">
                Job Survey Title
              </th>
              <th
                v-if="
                  currentUser.role === 'PowerUser' ||
                  currentUser.role === 'ClientUser'
                "
                class="min-w-140px"
              >
                Sender
              </th>
              <th v-else class="min-w-140px">Company</th>
              <th class="min-w-120px">Status</th>
              <th class="min-w-100px">Sent</th>
              <th class="text-end">Action</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in ques" :key="index">
              <tr>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="d-flex justify-content-start flex-column">
                      <p
                        v-if="surveytype === 'behavioral'"
                        class="text-dark fw-bolder fs-6 mb-0"
                      >
                        {{ item.recipientLastName }},
                        {{ item.recipientFirstName }}<br /><span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.recipientEmail }}<br />{{
                            item.clientLocationName
                          }}</span
                        >
                      </p>
                      <p
                        v-if="surveytype === 'job'"
                        class="text-dark fw-bolder fs-6 mb-0"
                      >
                        {{ item.surveyJobTitle }}<br />
                        <span class="text-muted fw-bold"
                          >{{ item.recipientFirstName }}
                          {{ item.recipientLastName }}</span
                        ><br /><span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.recipientEmail }}</span
                        >
                      </p>
                    </div>
                  </div>
                </td>

                <td
                  v-if="
                    currentUser.role === 'PowerUser' ||
                    currentUser.role === 'ClientUser'
                  "
                >
                  <p class="text-dark fw-bolder d-block fs-6">
                    {{ item.sentByName }}
                  </p>
                </td>
                <td v-else>
                  <p
                    v-if="item.clientName !== 'na'"
                    class="text-dark fw-bolder d-block fs-6"
                  >
                    {{ item.clientName }}
                  </p>
                  <p v-else class="text-dark fw-bolder d-block fs-6">
                    {{ item.sentByName }}
                  </p>
                </td>

                <td>
                  <div class="d-flex flex-column w-100 me-2">
                    <div class="d-flex flex-stack mb-2">
                      <span class="text-muted me-2 fs-7 fw-bold">
                        <span
                          v-if="item.completed"
                          class="badge badge-light-success"
                          >Completed</span
                        >
                        <span v-else class="badge badge-light-danger"
                          >Waiting</span
                        >
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <p class="text-dark fw-bolder d-block fs-6">
                    {{ d(item.sentDateUtc) }}
                  </p>
                </td>
                <td class="text-end">
                  <!--begin::Delete-->
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                    @click="deleteEmail(item.id)"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="../media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                  </a>
                  <!--end::Delete-->
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
    <!--begin::Footer-->
    <div class="border-0">
      <div
        class="card-toolbar col-sm-12 d-flex justify-content-end"
        style="padding: 0px 2.25rem 1.25rem 0px"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to View All Emails Sent"
      >
        <router-link
          v-if="surveytype === 'behavioral'"
          :to="{
            name: 'sent-surveys',
            params: {
              surveytype: surveytype,
            },
          }"
          class="btn btn-sm btn-light-primary"
        >
          View All<span class="svg-icon svg-icon-3">
            <inline-svg src="../media/icons/duotune/arrows/arr001.svg" /> </span
        ></router-link>
        <router-link
          v-if="surveytype === 'job'"
          :to="{
            name: 'sent-job-surveys',
            params: {
              surveytype: surveytype,
            },
          }"
          class="btn btn-sm btn-light-primary"
        >
          View All<span class="svg-icon svg-icon-3">
            <inline-svg src="../media/icons/duotune/arrows/arr001.svg" /> </span
        ></router-link>
      </div>
    </div>
    <!--end::Footer-->
  </div>
  <div v-else>
    <TableSkeleton></TableSkeleton>
  </div>
  <!--end::Survey Email Que Widget-->
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { EmailQueListRequestDto } from "../../../core/types/EmailTypes";
import { DeleteEmailQueRequestDto } from "../../../core/types/EmailTypes";

export default defineComponent({
  name: "survey-email-que-widget",
  components: { TableSkeleton },
  props: ["routeName", "surveytype"],
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const isDataReady = ref(false);
    const { d } = useI18n();
    const emailQueListRequestDto = ref<EmailQueListRequestDto>({
      userRole: "",
      userAspNetUserId: "",
      typeOfSurvey: props.surveytype,
      fromRoute: props.routeName,
    });

    const deleteEmailQueRequestDto = ref<DeleteEmailQueRequestDto>({
      id: 0,
      typeOfSurveyEmail: props.surveytype,
    });

    const ques = computed(() => {
      if (props.surveytype === "behavioral") {
        return store.getters.getAllQuedEmails;
      } else {
        return store.getters.getAllJobQuedEmails;
      }
    });

    const sendSurveyRouteName = computed(() => {
      let surveyRouteName = "";
      if (
        currentUser.role === "SuperAdmin" ||
        currentUser.role === "LicensedDistributor" ||
        currentUser.role === "LDAssociate"
      ) {
        surveyRouteName = "incline-email-survey";
      } else if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        surveyRouteName = "client-email-survey";
      }
      return surveyRouteName;
    });

    onMounted(() => {
      emailQueListRequestDto.value.userRole = currentUser.role;
      emailQueListRequestDto.value.userAspNetUserId = currentUser.id;
      store
        .dispatch(Actions.GET_ALL_EMAIL_QUES, emailQueListRequestDto.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving your sent emails.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    const deleteEmail = (id) => {
      Swal.fire({
        title: "Are you sure you want to remove this email?",
        showCancelButton: true,
        confirmButtonText: "Yes. Remove",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          deleteEmailQueRequestDto.value.id = id;

          store
            .dispatch(Actions.DELETE_EMAIL_QUE, deleteEmailQueRequestDto.value)
            .then(() => {
              Swal.fire("The email has been removed!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem removing the email.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    watch(ques, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      sendSurveyRouteName,
      isDataReady,
      emailQueListRequestDto,
      deleteEmailQueRequestDto,
      ques,
      d,
      deleteEmail,
    };
  },
});
</script>
