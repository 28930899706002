<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 gx-xl-12">
    <div class="col-xxl-12">
      <ContentExpertWidget
        v-if="
          (currentUser.role === 'PowerUser' ||
            currentUser.role === 'ClientUser') &&
          isContentExpertReady
        "
        :clientid="currentClient.client.clientId"
      ></ContentExpertWidget>
    </div>
  </div>
  <div class="row gy-5 gx-xl-8">
    <div class="col-xxl-8">
      <SurveyEmailQueWidget
        :routeName="routeName"
        :surveytype="'behavioral'"
        v-if="currentUser != 'Guest'"
      ></SurveyEmailQueWidget>
    </div>
    <div class="col-xxl-4">
      <ClientSurveyFoldersWidget
        v-if="
          currentUser.role === 'PowerUser' || currentUser.role === 'ClientUser'
        "
        :surveytype="'behavioral'"
      ></ClientSurveyFoldersWidget>
    </div>
  </div>
  <div class="row gy-5 g-xl-8">
    <div class="col-xxl-8">
      <SurveyEmailQueWidget
        :routeName="routeName"
        :surveytype="'job'"
        v-if="currentUser != 'Guest'"
      ></SurveyEmailQueWidget>
    </div>
    <!--<div class="col-xxl-4">
      <div class="incline-made_tommy_softbold">Made Tommy Soft Bold</div>
      <div class="incline-made_tommy_softmedium">Made Tommy Soft Medium</div>
      <div class="incline-made_tommy_softregular">Made Tommy Soft Regular</div>
      <div class="incline-cambay_devanagari-bold">Cambay Devanagari Bold</div>
      <div class="incline-cambay_devanagari-regular">
        Cambay Devanagari Regular
      </div>
    </div>-->
    <div class="col-xxl-4">
      <p>
        <ClientSurveyFoldersWidget
          v-if="
            currentUser.role === 'PowerUser' ||
            currentUser.role === 'ClientUser'
          "
          :surveytype="'job'"
        ></ClientSurveyFoldersWidget>
      </p>
    </div>
  </div>

  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import SurveyEmailQueWidget from "@/components/widgets/dashboard/SurveyEmailQue-Widget.vue";
import ClientSurveyFoldersWidget from "@/components/widgets/dashboard/Client-Survey-Folders-Widget.vue";
import ContentExpertWidget from "@/components/widgets/dashboard/Content-Expert-Widget.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    SurveyEmailQueWidget,
    ClientSurveyFoldersWidget,
    ContentExpertWidget,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const route = useRoute();
    const routeName = computed(() => route.name);
    onMounted(() => {
      setCurrentPageTitle("Dashboard");

      if (Object.keys(currentClient.value).length > 0) {
        isContentExpertReady.value = true;
      }
    });

    const isContentExpertReady = ref(false);

    watch(currentClient, () => {
      isContentExpertReady.value = true;
    });

    return {
      currentUser,
      currentClient,
      routeName,
      isContentExpertReady,
    };
  },
});
</script>
