<template>
  <!--begin::Card-->
  <div
    class="card card-flush mb-5"
    id="content-expert"
    data-kt-sticky="true"
    data-kt-sticky-name="view-content-expert"
    data-kt-sticky-offset="{default: false, lg: '200px'}"
    data-kt-sticky-width="{lg: '250px', xl: '300px'}"
    data-kt-sticky-left="auto"
    data-kt-sticky-top="150px"
    data-kt-sticky-animation="false"
    data-kt-sticky-zindex="95"
    v-if="isDataReady"
  >
    <!--begin::Card body-->
    <div class="card-body pt-1 pb-1 fs-6 incline-primary-dark-blue-bkgrd">
      <!--begin::Section-->
      <div class="mb-2 mt-2">
        <!--begin::Details-->
        <div class="d-flex align-items-center">
          <!--begin::Avatar-->
          <div class="symbol symbol-60px symbol-circle me-3">
            <img
              alt="Incline"
              src="media/logos/Incline_Submark_NavyCircle.png"
            />
          </div>
          <!--end::Avatar-->

          <!--begin::Info-->
          <div class="d-flex flex-column">
            <!--begin::Name-->
            <p class="fw-bold text-gray-300 mb-0">Your content expert</p>
            <p
              v-if="contentExpert.ldaName !== null"
              class="fs-4 fw-bolder text-white text-hover-secondary me-2 mb-0"
            >
              {{ contentExpert.ldaName }}
            </p>
            <p
              v-else
              class="fs-4 fw-bolder text-white text-hover-secondary me-2 mb-0"
            >
              {{ contentExpert.licensedDistributorName }}
            </p>
            <!--end::Name-->

            <!--begin::Email-->
            <a
              href="/contact-content-expert"
              class="fw-bold text-gray-300 text-hover-white"
              >For training needs or help interpreting results
              <span style="text-decoration: underline">contact me.</span></a
            >
            <!--end::Email-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>
      <!--end::Section-->
    </div>
    <!--end::Card body-->
  </div>
  <div v-else>
    <TableSkeleton></TableSkeleton>
  </div>
  <!--end::Card-->
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "content-expert",
  components: { TableSkeleton },
  props: ["clientid"],
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    const contentExpert = computed(() => {
      return store.getters.getClientContentExpert;
    });

    onMounted(() => {
      store
        .dispatch(Actions.GET_CONTENT_EXPERT, props.clientid)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving your content expert.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    watch(contentExpert, () => {
      isDataReady.value = true;
    });

    return {
      isDataReady,
      contentExpert,
    };
  },
});
</script>
